import React, { useState, useEffect } from "react"
import { Card, Button, Alert } from "react-bootstrap"
import { useAuth } from "../contexts/AuthContext"
import { Link, useHistory } from "react-router-dom"
import {db} from "../firebase"
import Collapse from 'react-bootstrap/Collapse'
import Table from 'react-bootstrap/Table'

export default function Experience(props) {
  


  const [error, setError] = useState("")
  const { currentUser, logout } = useAuth()
  const history = useHistory()

  async function handleLogout() {
    setError("")

    try {
      await logout()
      history.push("/login")
    } catch {
      setError("Failed to log out")
    }
  }

  // which document?
  const params = new URLSearchParams(props.history.location.search)
  const doc_id = params.get('doc_id')

  // Style of post title
  const postTitleStyle = {
    color: 'darkblue',
    fontSize: '1.5em'
  };
  const postTypeStyle = {
    fontWeight: 'bold'
  }


  

  // fetch post
  const [posts,setPosts]=useState([])
  const [company_slug, setCompanySlug] = useState([])
  const fetchPosts= async()=>{
    const response=db.collection('employee_experiences').where("doc_id", "==", doc_id);
    const data=await response.get();

    data.docs.forEach(item=>{
        setCompanySlug(item.data().company_slug)
        setPosts(item.data())
    })

  }
  
  useEffect(() => {
    fetchPosts();
  }, [])

  const [company_name,setCompanyName]=useState([])
  let posts2 = db.collection('employee_experiences').where("doc_id", "==", doc_id);
  posts2.get()
  .then((docSnaps) => {
    docSnaps.forEach((doc) => {
        let company_slug2 = doc.data().company_slug
      db.collection('companies').where("company_slug", "==", company_slug2).get()
          .then((userDoc) => {
        //loadedPosts[doc.id].userName = userDoc.data().name;
        if(userDoc != null){
            userDoc.docs.forEach(item=>{
                setCompanyName(item.data().company_name)
            })
        }
        
      });
    })
  });



  const [open, setOpen] = useState(false);
  // depending on if logged in
  function LoggedInPostView(props) {
    const postContent = props.postContent;
    const postContent_formatted = (postContent.split("_newline_").map((i,key) => {
      return <div key={key}>{i}</div>;
    }));

    return <div>{postContent_formatted}</div>
  }

  function GuestPostView(props) {
      console.log('guest')
    const postContent = props.postContent;
    // truncate to x chars for guests
    const postContent_formatted = (postContent.substring(0, 1000).concat('(...)').split("_newline_").map((i,key) => {
      return <div key={key}>{i}</div>;
    }));
    return <div>{postContent_formatted} <Link to='/login'>Log in</Link> or <Link to='/signup'>sign up</Link> to continue reading.</div>
  }
  function NeedPointsPostView(props) {
    const postContent = props.postContent;
    // truncate to x chars for guests
    const postContent_formatted = (postContent.substring(0, 1000).concat('(...)').split("_newline_").map((i,key) => {
      return <div key={key}>{i}</div>;
    }));
    return <div>{postContent_formatted} <b>To continue reading, please <Link to='/contribute'>contribute</Link> a post.</b></div>
  }

  function PostView(props) {

    

    const postContent = props.postContent;
    const isLoggedIn = props.isLoggedIn;


    // don't need login or contribute for now
    // TODO: delete this line later
    return <LoggedInPostView postContent={postContent} />;

    // check if enough points
    const [user_points,setUserPoints]=useState(0)
    if (isLoggedIn == null) {
        console.log(isLoggedIn)
        console.log('returning guest')
        return <GuestPostView postContent={postContent} />;
    }
    if(currentUser == null){ // clicked logout on this page
        return <GuestPostView postContent={postContent} />;
    }
    let user_points_query = db.collection('user_details').doc(currentUser.email);
    user_points_query.get()
    .then((userSnaps) => {
        if(userSnaps.data()){
            setUserPoints(userSnaps.data().points)
            console.log('user_points')
            console.log(user_points)
              
            
        }
    })
    if(user_points > 0){
        return <LoggedInPostView postContent={postContent} />;
    } else {
        console.log('need points')
        return <NeedPointsPostView postContent={postContent} />;
    } 
}
    
    
    
  

  
  //const posts_dict = posts.reduce(function(p, c) { p[c[0]] = c[1]; return p; }, {});
  
  if(posts.content == null){
    return (<div class="divLoader">
      <img src="/loading.gif" alt="Loading..." height="45px" width="45px" />
      Loading
    </div>)
  }

  //fetchCompanyData()

  /*
  if(companyData.company_name == null){
      return (<div class="divLoader">
      <img src="/loading.gif" alt="Loading..." height="45px" width="45px" />
      Loading
    </div>)
  }*/

    
  return (
    <div className="App">
        <div>Company: <Link to={'/company?company_slug=' + company_slug}>{company_name}</Link></div>
        <h1>{posts.post_title}</h1>
        <Card bg={'success'} text={'white'} style={{ width: '18rem' }}>
            <Card.Body>
            <div><b>Job Position:</b> {posts.job_position}</div>
            <div><b>Org/Product:</b> {posts.org}</div>
            <div><b>Location:</b> {posts.location}</div>
            <div><b>Years of Experience:</b> {posts.yoe}</div>
            </Card.Body>
        </Card>

        <PostView postContent={posts.content} isLoggedIn={(currentUser != null)} />
        
    </div>
    
    
  );




    }
