import React, { useState, useEffect, useRef } from "react";
import { Form, Card, Alert } from "react-bootstrap";
import { useAuth } from "../contexts/AuthContext";
import { Link, useHistory } from "react-router-dom";
import { db } from "../firebase";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import { borders } from '@material-ui/system';
import Grid from '@material-ui/core/Grid';
import firebase from 'firebase/app'


var gibberish = require("gibberish-detector");

export default function Contribute() {
  const companyNameRef = useRef();
  const orgNameRef = useRef();
  const jobLocationRef = useRef();
  const jobPositionRef = useRef();
  const yoeRef = useRef();
  const postTypeRef = useRef();
  const postTitleRef = useRef();
  const postContentRef = useRef();

  const { currentUser, logout } = useAuth();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  // form styling
  const useStyles = makeStyles((theme) => ({
    formStyle: {
      maxWidth: 1000,
      align: "center"
    },
    formControl: {
      minWidth: 100,
    },

    boxStyle: {
      border: "1px solid",
      borderRadius: 10,
      padding: "20px",
      margin: "10px",
    },

    textFieldStyle: {
      padding: "10px",
      margin: "10px",
    }
  }));

  const classes = useStyles();

  async function storeDocument(
    companyName,
    orgName,
    jobLocation,
    jobPosition,
    yoe,
    postType,
    postTitle,
    postContent
  ) {
    // store Document into db

    // handle newlines in post content
    let postContent_formatted = postContent.replace(/\n/g, "_newline_");

    var docData = {
      company_name: companyName, // we only stored company slug tho
      org: orgName,
      location: jobLocation,
      job_position: jobPosition,
      yoe: parseInt(yoe),
      post_type: postType,
      post_title: postTitle,
      content: postContent_formatted,
      createdAt: new Date().toISOString(),
      moderated: false,
      createdBy: currentUser.email,
    };

    // add in doc_id
    let newDoc = db.collection("employee_experiences").doc();
    newDoc.set(docData).then(() => {
      db.collection("employee_experiences").doc(newDoc.id).update({
        doc_id: newDoc.id,
      });
    }).then(() => {
      // increment user points
      const increment = firebase.firestore.FieldValue.increment(1);

      let userDoc = db.collection("user_details").doc(currentUser.email);
      
      userDoc.update({points: increment}).then(() => {
        db.collection("employee_experiences").doc(newDoc.id).update({
          doc_id: newDoc.id,
        });
      });
    });


    
  }

  async function handleSubmit(e) {
    e.preventDefault();

    console.log(companyNameRef.current.value);

    if (
      !/^\d+$/.test(yoeRef.current.value) ||
      parseInt(yoeRef.current.value) > 40
    ) {
      return setError("Years of experience must be an integer");
    }

    if (companyNameRef.current.value.length > 30) {
      return setError("Company name is too long");
    }
    if (orgNameRef.current.value.length > 40) {
      return setError("Org name is too long");
    }
    if (jobLocationRef.current.value.length > 40) {
      return setError("Job location is too long");
    }
    if (jobPositionRef.current.value.length > 40) {
      return setError("Job position is too long");
    }
    if (postTitleRef.current.value.length > 100) {
      return setError("Post title is too long");
    }

    if (postTitleRef.current.value.length < 6) {
      return setError("Post title must be 6 char or greater");
    }
    if (postContentRef.current.value.length < 100) {
      return setError("Post content must be 100 char or greater");
    }
    if (postContentRef.current.value.length > 70000) {
      return setError("Post content must be less than 70000 char");
    }

    if (false) {
      console.log(gibberish.detect(postTitleRef.current.value));
      return setError("Correct post title");
    }

    const content_gibberish_score = gibberish.detect(
      postContentRef.current.value
    );
    if (false) {
      console.log(content_gibberish_score);
      return setError("Correct post content");
    }

    /*
    if (passwordRef.current.value !== passwordConfirmRef.current.value) {
      return setError("Passwords do not match")
    }

    try {
      setError("")
      setLoading(true)
      await signup(emailRef.current.value, passwordRef.current.value, displayNameRef.current.value)
      history.push("/")
    } catch {
      setError("Failed to create an account") // TODO give more specific reason based on error code
    }*/

    try {
      setError("");
      setLoading(true);
      await storeDocument(
        companyNameRef.current.value,
        orgNameRef.current.value,
        jobLocationRef.current.value,
        jobPositionRef.current.value,
        yoeRef.current.value,
        postTypeRef.current.value,
        postTitleRef.current.value,
        postContentRef.current.value
      );
      history.push("/contribute_success");
    } catch {
      setError("Failed to submit"); // TODO give more specific reason based on error code
    }
  }

  function isNumber(evt) {
    evt = evt ? evt : window.event;
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  return (
    <>

          <Typography variant="h3" align="center" gutterBottom>
            Contribute
          </Typography>
          <Form onSubmit={handleSubmit} className={classes.formStyle}>
            <Box className={classes.boxStyle}>

            <Container>
              <Typography variant="h5" gutterBottom>
                Company Details
              </Typography>

            </Container>



            <Grid container spacing={3}>
        <Grid item xs>
        <Container className={classes.textFieldStyle}>
                <TextField
                  label="Company Name"
                  inputRef={companyNameRef}
                  required
                />
            </Container>
        </Grid>
        <Grid item xs>
        <Container className={classes.textFieldStyle}>
                <TextField label="Org Name" inputRef={orgNameRef} required />
            </Container>
                    </Grid>
        <Grid item xs>
        <Container className={classes.textFieldStyle}>
                <TextField
                  label="Job Location"
                  inputRef={jobLocationRef}
                  required
                />
            </Container>    
                </Grid>
      </Grid>
    
            </Box>
            <Box className={classes.boxStyle}>
            <Container >
              
              <Typography variant="h5" gutterBottom>
                Job Details
              </Typography>
            </Container>

            <Grid container spacing={3}>
        <Grid item xs>
        <Container className={classes.textFieldStyle}>
        <TextField
                  label="Job Position"
                  inputRef={jobPositionRef}
                  required
                />
                
            </Container>
                    </Grid>
        <Grid item xs>
          <Container className={classes.textFieldStyle}>
        <TextField
                  label="Years of Experience"
                  inputRef={yoeRef}
                  required
                />
                </Container>
                      </Grid>

      </Grid>
            

            </Box>
            <Box className={classes.boxStyle}>
            <Container>
              
              <Typography variant="h5" gutterBottom>
                Post Details
              </Typography>
            </Container>
            <Container>
              <FormControl>
                <InputLabel id="label">Post Type</InputLabel>
                <Select
                  labelId="label"
                  id="select"
                  inputRef={postTypeRef}
                  className={classes.formControl}
                  required
                >
                  <MenuItem value="Advice">Advice</MenuItem>
                  <MenuItem value="Interview">Interview</MenuItem>
                  <MenuItem value="Internal Transfer">
                    Internal Transfer
                  </MenuItem>
                  <MenuItem value="Performance Improvement Plan">
                    Performance Improvement Plan
                  </MenuItem>
                  <MenuItem value="Promotion">Promotion</MenuItem>
                </Select>
              </FormControl>
            </Container>

            <Container className={classes.textFieldStyle}>
        <TextField
                  label="Post Title"
                  inputRef={postTitleRef}
                  required
                />
                </Container>


            

            <Container maxWidth="lg">
              <FormControl fullWidth="True">
                <TextField
                  id="outlined-multiline-static"
                  label="Post Content"
                  multiline
                  rows={8}
                  width={1}
                  inputRef={postContentRef}
                  required
                />
              </FormControl>
            </Container>
            </Box>
            <Container>
              {error && <Alert variant="danger">{error}</Alert>}
            </Container>
            <Container>
              <Button disabled={loading} className="w-100" type="submit" color="primary" variant="outlined" >
                Submit
              </Button>
            </Container>
          </Form>

    </>
    /*
    <>
      <Card>
        <Card.Body>
          <h2 className="text-center mb-4">Contribute</h2>
          
          <Form onSubmit={handleSubmit}>
            
            <Form.Group id="companyName">
              <Form.Label>Company Name</Form.Label>
              <Form.Control type="companyName" ref={companyNameRef } placeholder="Ex: Facebook, Amazon, Google, etc" required />
            </Form.Group>
            <Form.Group id="orgName">
              <Form.Label>Org Name</Form.Label>
              <Form.Control type="orgName" ref={orgNameRef} placeholder="Ex: Ads, AWS, Windows, etc" required />
            </Form.Group>
            <Form.Group id="jobLocation">
              <Form.Label>Job Location</Form.Label>
              <Form.Control type="jobLocation" ref={jobLocationRef} placeholder="Ex: Bay Area, Seattle, New York, etc" required />
            </Form.Group>
            <Form.Group id="jobPosition">
              <Form.Label>Job Position</Form.Label>
              <Form.Control type="jobPosition" ref={jobPositionRef} placeholder="Ex: Software Engineer, Product Manager, Data Scientist, etc" required />
            </Form.Group>
            <Form.Group id="yoe">
              <Form.Label>Years of Experience</Form.Label>
              <Form.Control type="yoe" ref={yoeRef} required />
            </Form.Group>
            <Form.Group id="postType">
              <Form.Label>Post Type</Form.Label>

              <select

                ref={postTypeRef}
              >
                <option>Advice</option>
                <option>Interview</option>
                <option>Internal Transfer</option>
                <option>Performance Improvement Plan</option>
                <option>Promotion</option>
              </select>

            </Form.Group>
            <Form.Group id="postTitle">
              <Form.Label>Post Title</Form.Label>
              <Form.Control type="postTitle" ref={postTitleRef} required />
            </Form.Group>
            <Form.Group id="postContent">
              <Form.Label>Post Content</Form.Label>
              <Form.Control as="textarea" type="postContent" ref={postContentRef} required />
            </Form.Group>
            {error && <Alert variant="danger">{error}</Alert>}
            <Button disabled={loading} className="w-100" type="submit">
              Submit
            </Button>
          </Form>
        </Card.Body>
      </Card>
      
    </>*/
  );
}
