import React, { useRef, useState } from "react"
import { Form, Button, Card, Alert } from "react-bootstrap"
import { useAuth } from "../contexts/AuthContext"
import { Link, useHistory } from "react-router-dom"

import { db } from "../firebase";


export default function Signup() {
  const displayNameRef = useRef()
  const emailRef = useRef()
  const passwordRef = useRef()
  const passwordConfirmRef = useRef()
  const { signup } = useAuth()
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)
  const history = useHistory()

  async function handleSubmit(e) {
    e.preventDefault()

    setLoading(true)

    setError('') // in case there's some stale error

    if (passwordRef.current.value !== passwordConfirmRef.current.value) {
        setLoading(false)
      return setError("Passwords do not match")
    }
    console.log('handling submit')

    // check if display name is unique
    let checkDisplayNameDoc = db.collection("user_details").where("displayName", "==", displayNameRef.current.value);
    const querySnapshot = await checkDisplayNameDoc.get()
    if(querySnapshot.size != 0) {
        console.log('Display name already exists')
        setError('Display name already exists, please pick another one.')
        setLoading(false)
        return;
    }
    let err = 'bloop'
    try{
        err = await signup(emailRef.current.value, passwordRef.current.value, displayNameRef.current.value)
    } catch (error) {
        setError(error)
        setLoading(false)
    }
    setLoading(false)
    
    if(err) {
        setError(err.message)
        return;
    }

    history.push('/')

  }

  return (
    <>
      <Card>
        <Card.Body>
          <h2 className="text-center mb-4">Sign Up</h2>
          {error && <Alert variant="danger">{error}</Alert>}
          <Form onSubmit={handleSubmit}>
            <Form.Group id="displayName">
              <Form.Label>Display Name</Form.Label>
              <Form.Control type="displayName" ref={displayNameRef} required />
            </Form.Group>
            <Form.Group id="email">
              <Form.Label>Email</Form.Label>
              <Form.Control type="email" ref={emailRef} required />
            </Form.Group>
            <Form.Group id="password">
              <Form.Label>Password</Form.Label>
              <Form.Control type="password" ref={passwordRef} required />
            </Form.Group>
            <Form.Group id="password-confirm">
              <Form.Label>Password Confirmation</Form.Label>
              <Form.Control type="password" ref={passwordConfirmRef} required />
            </Form.Group>
            <Button disabled={loading} className="w-100" type="submit">
              Sign Up
            </Button>
          </Form>
        </Card.Body>
      </Card>
      <div className="w-100 text-center mt-2">
        Already have an account? <Link to="/login">Log In</Link>
      </div>
    </>
  )
}