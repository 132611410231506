import React, { useState, useEffect } from "react"
import { Card, Button, Alert } from "react-bootstrap"
import { useAuth } from "../contexts/AuthContext"
import { Link, useHistory } from "react-router-dom"
import {db} from "../firebase"
import Typography from '@material-ui/core/Typography';
import CheckIcon from '@material-ui/icons/Check';


export default function Homepage() {
  const [error, setError] = useState("")
  const { currentUser, logout } = useAuth()
  const history = useHistory()

  async function handleLogout() {
    setError("")

    try {
      await logout()
      history.push("/login")
    } catch {
      setError("Failed to log out")
    }
  }



  // Print out company metadata
  const [companies, setCompanies] = useState([])
  const fetchCompanies=async()=>{
    const response=db.collection('companies');
    const data=await response.get();
    const companies = []
    data.docs.forEach(item=>{
      companies.push(item.data())   
    })
    setCompanies(companies) 
  }
  useEffect(() => {
    fetchCompanies();
  }, [])

  

  return (
    <div className="App">
      
        <>
        <p></p>
        <Typography variant="h1" align="center" gutterBottom>
          Larkboard
        </Typography>
        <Typography variant="h5" align="center" gutterBottom>
          The hub for tech company information and insights.
        </Typography>
        
        <Typography variant="h6" align="center" gutterBottom>
          <CheckIcon /> Company and org/team reviews.
        </Typography>
        <Typography variant="h6" align="center" gutterBottom>
          <CheckIcon /> Interview advice.
        </Typography>
        <Typography variant="h6" align="center" gutterBottom>
          <CheckIcon /> Career advice.
        </Typography>

        <Typography variant="h5" gutterBottom>
          Companies:
        </Typography>
        </>
{
        companies && companies.map(company=>{
          return(
            <>
            

            <div className="blog-container">
              <h4>{company.company_name}</h4>
              <p>{company.company_hq}</p>
              
              <Link to={'/company?company_slug=' + company.company_slug}>{company.company_name}</Link>
            </div>
            </>
          )
        })
      }
    </div>
  );

}
