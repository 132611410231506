import React, { useState, useEffect, useRef } from "react";
import { Form, Card, Alert } from "react-bootstrap";
import { useAuth } from "../contexts/AuthContext";
import { Link, useHistory } from "react-router-dom";
import { db } from "../firebase";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import { borders } from '@material-ui/system';
import Grid from '@material-ui/core/Grid';


var gibberish = require("gibberish-detector");

export default function ContributeSuccess() {


  const { currentUser, logout } = useAuth();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  return (
    <>
    <Typography variant="h4">
        Success!
    </Typography>
    <Typography variant="body1" gutterBottom>
        Thank you for your contribution. It is now submitted for moderation, before appearing on the website.
    </Typography>
          </>
  );
}
