import React, { useState, useEffect } from "react"
import { Card, Button, Alert } from "react-bootstrap"
import { useAuth } from "../contexts/AuthContext"
import { Link, useHistory } from "react-router-dom"
import {db} from "../firebase"
import Collapse from 'react-bootstrap/Collapse'
import Table from 'react-bootstrap/Table'

export default function Company(props) {
  


  const [error, setError] = useState("")
  const { currentUser, logout } = useAuth()
  const history = useHistory()

  async function handleLogout() {
    setError("")

    try {
      await logout()
      history.push("/login")
    } catch {
      setError("Failed to log out")
    }
  }

  // which company?
  const params = new URLSearchParams(props.history.location.search)
  const company_slug = params.get('company_slug')

  // Style of post title
  const postTitleStyle = {
    color: 'darkblue',
    fontSize: '1.5em'
  };
  const postTypeStyle = {
    fontWeight: 'bold'
  }

  // Print out company metadata
  const [companyData, setCompanyData] = useState([])
  const fetchCompanyData=async()=>{
    const response=db.collection('companies').where("company_slug", "==", company_slug);
    const data=await response.get();
    data.docs.forEach(item=>{
      setCompanyData(item.data())    
    })
  }
  useEffect(() => {
    fetchCompanyData();
  }, [])

  // Get the different types of posts
  const [postCounts, setPostCounts] = useState({})
  const fetchPostCounts=async()=>{
    const response=db.collection('employee_experiences').where("company_slug", "==", company_slug).where("moderated", "==", true);
    const data=await response.get();
    const postCounts = {'PIP': 0,'Advice': 0}
    data.docs.forEach(item=>{
      console.log(item.data())
      postCounts[item.data().post_type] += 1
    })
    setPostCounts(postCounts)
    console.log(postCounts)
  }
  useEffect(() => {
    fetchPostCounts();
  }, [])




  const [blogs,setBlogs]=useState([])
  const fetchBlogs=async()=>{
    const response=db.collection('employee_experiences').where("company_slug", "==", company_slug).where("moderated", "==", true);
    const data=await response.get();
    const fullList = []
    data.docs.forEach(item=>{
      fullList.push(item.data())     
    })
    setBlogs(fullList)
  }
  useEffect(() => {
    fetchBlogs();
  }, [])

  const [open, setOpen] = useState(false);
  // depending on if logged in
  function LoggedInPostView(props) {
    const postContent = props.postContent;
    const doc_id = props.doc_id;
    const postContent_formatted = (postContent.substring(0, 200).concat('(...)').split("_newline_").map((i,key) => {
      return <div key={key}>{i}</div>;
    }));

    return <div>{postContent_formatted} <Link to={'/experience?doc_id=' + doc_id}>Read More</Link></div>
  }

  function GuestPostView(props) {
    const postContent = props.postContent;
    // truncate to x chars for guests
    const postContent_formatted = (postContent.substring(0, 200).concat('(...)').split("_newline_").map((i,key) => {
      return <div key={key}>{i}</div>;
    }));
    return <div>{postContent_formatted} <Link to='/login'>Log in</Link> or <Link to='/signup'>sign up</Link> to continue reading.</div>
  }
  function PostView(props) {
    const postContent = props.postContent;
    const isLoggedIn = props.isLoggedIn;
    const doc_id = props.doc_id;
    if (isLoggedIn) {
      return <LoggedInPostView postContent={postContent} doc_id={doc_id} />;
    }
    return <GuestPostView postContent={postContent} />;
  }


  return (
    <div className="App">
      <h1>{companyData.company_name}</h1>
      <h2>{companyData.company_hq}</h2>

      {
        // get the different types of posts

      }
      <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>Post</th>
                        <th>Employee</th>
                        <th>Org / Location</th>
                      </tr>
                    </thead>
                    <tbody>
      {
        
        blogs && blogs.map(blog=>{
          return(
            <>
                <tr>
          <td>
            <div style={postTitleStyle}>
              <Link to={'/experience?doc_id=' + blog.doc_id}>{blog.post_title}</Link></div>
              <div style={postTypeStyle}>{blog.post_type}</div>
              {//<PostView postContent={blog.content} isLoggedIn={(currentUser != null)} doc_id={blog.doc_id.trim()} />
              }
              <PostView postContent={blog.content} isLoggedIn={true} doc_id={blog.doc_id.trim()} />
          </td>
          <td>{blog.job_position} <br /> {blog.yoe} years of experience</td>
          <td><div>{blog.org}</div> <div>{blog.location}</div></td>
                </tr>
              </>

            /*
            <Card>
              <Card.Body>
                <Card.Title>
                  {blog.post_title}
                </Card.Title>
                <Card.Subtitle>
                  <div>Position: {blog.job_position}</div>
                  <div>Org: {blog.org}</div>
                  <div>YOE: {blog.yoe}</div>
                  <div>Post Type: {blog.post_type}</div>

                </Card.Subtitle>
                <Card.Text>
                
                    <div>

                  <PostView postContent={blog.content} isLoggedIn={(currentUser != null)} />
      
                  </div>
                  
                  
                </Card.Text>
              </Card.Body>
            </Card>*/
          )
        })
        
      }
      </tbody>
            </Table>
    </div>
  );




    }
