import React, { useState, useEffect } from "react"
import { NavLink } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext"
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Button from "react-bootstrap/Button";
import { useHistory } from "react-router-dom"


function Header() {

  const [error, setError] = useState("")
  const { currentUser, logout } = useAuth()
  const history = useHistory()

  async function handleLogout() {
    setError("")

    try {
      await logout()
      history.push("/login")
    } catch {
      setError("Failed to log out")
    }
  }

  const [open, setOpen] = useState(false);
  // depending on if logged in
  function LoggedInPostView(props) {
      return (
        <>
        <Nav.Link href="/contribute">Contribute</Nav.Link>
        <Nav.Link onClick={handleLogout}>
          Log Out
        </Nav.Link>
        </>
      );
  };
  function GuestPostView(props) {
    return (
      <>
    <Nav.Link href="/login">Login</Nav.Link>
    <Nav.Link href="/signup">Sign Up</Nav.Link>
    </>
    );
};
  function PostView(props) {
    const isLoggedIn = props.isLoggedIn;
    if (isLoggedIn) {
      return <LoggedInPostView />;
    }
    return <GuestPostView />;
  }

  return (
    <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
  <Navbar.Brand href="/homepage">Larkboard</Navbar.Brand>
  <Navbar.Toggle aria-controls="responsive-navbar-nav" />
  <Navbar.Collapse id="responsive-navbar-nav">
    <Nav className="mr-auto">
      <Nav.Link href="/homepage">Home</Nav.Link>
      <Nav.Link href="/about">About</Nav.Link>
      <PostView isLoggedIn={(currentUser != null)} />
      
    </Nav>
  </Navbar.Collapse>
</Navbar>
  );
}
export default Header;