import React from "react"
import Signup from "./Signup"
import { Container } from "react-bootstrap"
import { AuthProvider } from "../contexts/AuthContext"
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
import Dashboard from "./Dashboard"
import Login from "./Login"
import PrivateRoute from "./PrivateRoute"
import ForgotPassword from "./ForgotPassword"
import UpdateProfile from "./UpdateProfile"
import Homepage from "./Homepage"
import Company from "./Company"
import Experience from "./Experience"
import Contribute from "./Contribute"
import ContributeSuccess from "./ContributeSuccess"
import About from "./About"




import Header from "./header";
import Footer from "./footer";


function App() {

  

  
  return (
    <Router>
    {/*
    <Container
      className="d-flex align-items-center justify-content-center"
      style={{ minHeight: "100vh" }}
    > */}

      {/*<div className="w-100" style={{ maxWidth: "1500px" }}>*/}
      <div>
        
          <AuthProvider>
          <Header />
          
            <Container>
            <Switch>
              <PrivateRoute exact path="/" component={Dashboard} />
              <PrivateRoute path="/update-profile" component={UpdateProfile} />
              <Route path="/homepage" component={Homepage} />
              <Route path="/signup" component={Signup} />
              <Route path="/login" component={Login} />
              <Route path="/forgot-password" component={ForgotPassword} />
              <Route path="/company" component={Company} />
              <Route path="/experience" component={Experience} />
              <Route path="/contribute" component={Contribute} />
              <Route path="/contribute_success" component={ContributeSuccess} />
              <Route path="/about" component={About} />
            </Switch>
            </Container>
          </AuthProvider>
        
      </div>
    {/*</Container>*/}
    <Footer />
    </Router>
  )
}

export default App