import React from "react";
import { AppBar, Toolbar, Typography, Container } from "@material-ui/core";


function Footer() {
  return (
    <>
    <br/>
    <AppBar position="static" style={{background: '#212529'}} >
          <Container maxWidth="md">
            <Toolbar style={{background: '#212529'}}>
              <Typography variant="body1" >
                &copy; 2021 Larkboard
              </Typography>
            </Toolbar>
          </Container>
        </AppBar>
        </>
  );
}

export default Footer;