import React, { useState, useEffect } from "react"
import { Card, Button, Alert } from "react-bootstrap"
import { useAuth } from "../contexts/AuthContext"
import { Link, useHistory } from "react-router-dom"
import {db} from "../firebase"
import Typography from '@material-ui/core/Typography';


export default function About() {
  const [error, setError] = useState("")
  const { currentUser, logout } = useAuth()
  const history = useHistory()

  async function handleLogout() {
    setError("")

    try {
      await logout()
      history.push("/login")
    } catch {
      setError("Failed to log out")
    }
  }


  

  return (
    
            <>
            <p></p>
            <Typography variant="h4" align="center" gutterBottom>
              About Larkboard
            </Typography>
            <Typography variant="body1" gutterBottom>
              Larkboard is a community-driven website that aims to provide information about tech companies.
            </Typography>
            
            <Typography variant="body1" gutterBottom>
              As all the information on Larkboard is user-submitted, therefore Larkboard cannot guarantee the accuracy of any information on this website. So, do understand that Larkboard cannot assume responsibility for any false information on this site. If you see something fishy, please report it to us.
            </Typography>

            <Typography variant="body1" gutterBottom>
              The website is still in active development. Have feedback or want to report a bug? Please visit here: <a href="https://docs.google.com/forms/d/e/1FAIpQLSfBiWOdkxD22m_67sU9uL0ZYTGAfovCK0S9By1zmakzP10fEA/viewform">Feedback and Comments</a>
            </Typography>

            <Typography variant="body1" gutterBottom>
              If you wish to support us, please share the website with your friends :)
            </Typography>

            </>
          );

  

}
